import { useEffect, useState } from "react";
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import {
  Button, ColorInput,
  Container,
  Divider,
  Grid,
  Group,
  Modal,
  Overlay,
  Select,
  Switch,
  TextInput,
  Title
} from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../utils/hooks"
import { IconSettings, IconPalette, IconCheck, IconCirclePlus, IconLayoutSidebar } from "@tabler/icons"
import { useForm } from "@mantine/form"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc
} from "firebase/firestore"
import { db } from "../api/api-firebase"
import { DateRangePicker } from "@mantine/dates"
import { addDays, currentDate, secondsToDate } from "../utils/utils"
import { showNotification } from "@mantine/notifications"
import { setGanttShowSidebar, setGanttViewDefault } from "../store/reducers/general"

export function Dashboard() {

  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [modalAddTaskOpened, setModalAddTaskOpened] = useState(false)
  const [modalEditTaskOpened, setModalEditTaskOpened] = useState(false)
  const [modalSettingsOpened, setModalSettingsOpened] = useState(false)
  const [tasks, setTasks] = useState<any[]>([])
  const [taskTempToEdit, setTaskTempToEdit] = useState<Task | null>(null)

  const user = useAppSelector((state) => state.user)
  const ganttViewDefault = useAppSelector((state) => state.general.ganttViewDefault)
  const ganttShowSidebar = useAppSelector((state) => state.general.ganttShowSidebar)

  const handleDateChange = async (task: Task) => {

    // Aggiorno task su firebase
    const docRef = doc(db, "users", user.data.docId, 'tasks', task.id);
    await updateDoc(docRef, {
      start: task.start,
      end: task.end,
    })
  }

  const form = useForm({
    initialValues: {
      name: '',
      range: [currentDate, addDays(currentDate, 10)],
    }
  })

  const formEdit = useForm({
    initialValues: {
      name: '',
      range: [currentDate, currentDate],
    }
  })

  const formSettings = useForm({
    initialValues: {
      tasksColor: user.data.tasksColor,
    }
  })

  const handleTaskEdit = async (values: any) => {

    setIsLoading(true)

    let docRef = taskTempToEdit && doc(db, "users", user.data.docId, 'tasks', taskTempToEdit.id);

    docRef ?
      await updateDoc(docRef, {
        name: values.name,
        start: values.range[0],
        end: values.range[1]
      })
      :
      console.log('docRef non trovato')

    setIsLoading(false)
    setModalEditTaskOpened(false)
    formEdit.reset();

    showNotification({
      title: "Task modificata correttamente",
      message: `"${values.name}" è stata modificata`,
      icon: <IconCheck/>,
    })

  }

  const handleDeleteTask = async (id: string) => {

    setIsLoading(true)

    const docRef = doc(db, "users", user.data.docId, 'tasks', id);
    await deleteDoc(docRef)

    setIsLoading(false)
    setModalEditTaskOpened(false)
    formEdit.reset();

    showNotification({
      title: "Task eliminata",
      message: `La task è stata eliminata correttamente`,
      icon: <IconCheck/>,
    })

  }

  const handleSubmitNewTask = async (values: any) => {

    setIsLoading(true)

    await addDoc(
      collection(db, "users", user.data.docId, 'tasks'),
      {
        addedOn: serverTimestamp(),
        start: values.range[0],
        end: values.range[1],
        name: values.name,
      }
    )

    form.reset();
    setIsLoading(false)
    setModalAddTaskOpened(false)

  }

  const handleSettings = async (values: any) => {

    setIsLoading(true)

    const docRef = doc(db, "users", user.data.docId);
    await updateDoc(docRef, {
      tasksColor: values.tasksColor,
    })

    setIsLoading(false)
    setModalSettingsOpened(false)
    showNotification({
      title: "Impostazioni salvate correttamente",
      message: `Aggiorna la pagina per vedere i cambiamenti`,
      icon: <IconCheck/>,
    })

  }

  useEffect(() => {
    if (user.data.docId !== "") {

      // Listener sulle tasks
      const qTasks = query(collection(db, "users", user.data.docId, 'tasks'), orderBy("addedOn", "desc"));
      const unsubscribeTasks = onSnapshot(qTasks, snapshot => {

        if (snapshot.docs[0]) {

          const docs = snapshot.docs;

          let storeTasks: any[] = [];

          docs.map((task) => {
            const storeData = task.data();

            // Modeler
            let storeTask = {
              id: task.id,
              start: secondsToDate(storeData.start.seconds),
              end: secondsToDate(storeData.end.seconds),
              name: storeData.name,
              progress: 100,
              type: "task",
              styles: {
                progressColor: user.data.tasksColor,
                progressSelectedColor: user.data.tasksColor,
              }
            };

            return storeTasks = [...storeTasks, storeTask];
          })

          setTasks(storeTasks)

        }

      })

      return () => {
        unsubscribeTasks();
      }
    }
  }, [user.data.docId])

  // useEffect(() => {
  //   console.log(user.data.tasksColor)
  //   formSettings.setFieldValue('tasksColor', user.data.tasksColor)
  // }, [user])

  return (
    <>
      <Container mb="xl">

        <Group position="apart" align="center">

          <Title order={4}>Le mie task</Title>

          <Group spacing="xs">
            <Button
              leftIcon={<IconCirclePlus size={18}/>}
              variant="outline"
              onClick={() => setModalAddTaskOpened(true)}>
              Nuova task
            </Button>

            <Button
              leftIcon={<IconSettings size={18}/>}
              variant="outline"
              onClick={() => setModalSettingsOpened(true)}>
              Impostazioni
            </Button>
          </Group>

        </Group>

        <Divider my="sm" variant="dotted"/>

        <Group position="apart" align="center">

          <Switch
            checked={ganttShowSidebar}
            label="Mostra sidebar"
            onChange={(event) => dispatch(setGanttShowSidebar(event.currentTarget.checked))}
          />

          <Select
            defaultValue={ganttViewDefault}
            placeholder="Visualizzazione per"
            data={[
              { value: ViewMode.Month, label: 'Vedi per mese' },
              { value: ViewMode.Week, label: 'Vedi per settimana' },
              { value: ViewMode.Day, label: 'Vedi per giorno' },
            ]}
            onChange={(value) => {
              dispatch(setGanttViewDefault(value as ViewMode))
            }}
          />

        </Group>

      </Container>

      <Container fluid>
        {
          tasks.length > 0 &&
            <Gantt
              tasks={tasks}
              locale="it"
              onDateChange={data => {
                handleDateChange(data)
              }}
              viewMode={ganttViewDefault}
              onDoubleClick={task => {

                // Setto task temporaneo da modificare
                setTaskTempToEdit(task);

                // Setto i valori del form di modifica
                formEdit.setFieldValue('name', task.name)
                formEdit.setFieldValue('range', [task.start, task.end])

                // Apro la modal di modifica
                setModalEditTaskOpened(true)
              }}
              listCellWidth={ganttShowSidebar ? "150px" : ""}
            />
        }
      </Container>

      <Modal
        opened={modalAddTaskOpened}
        onClose={() => setModalAddTaskOpened(false)}
        title="Crea nuova task"
      >
        <form
          onSubmit={form.onSubmit((values) => {
            handleSubmitNewTask(values)
          })}>

          {isLoading && <Overlay opacity={0.3} color="#fff"/>}

          <TextInput
            label="Nome task"
            maxLength={30}
            required
            {...form.getInputProps('name')}
          />

          <DateRangePicker
            mt={10}
            label="Programmazione"
            placeholder="Data di inizio e fine"
            required
            {...form.getInputProps('range')}
          />

          <Button
            type="submit"
            fullWidth
            mt="xl"
            loading={isLoading}
          >
            Aggiungi task
          </Button>

        </form>
      </Modal>

      <Modal
        opened={modalEditTaskOpened}
        onClose={() => {
          setModalEditTaskOpened(false)
          formEdit.reset()
        }}
        title="Modifica task"
      >
        <form
          onSubmit={formEdit.onSubmit((values) => {
            handleTaskEdit(values)
          })}>

          {isLoading && <Overlay opacity={0.3} color="#fff"/>}

          <TextInput
            label="Nome task"
            maxLength={30}
            required
            {...formEdit.getInputProps('name')}
          />

          <DateRangePicker
            mt={10}
            label="Programmazione"
            placeholder="Data di inizio e fine"
            required
            {...formEdit.getInputProps('range')}
          />

          <Grid columns={8}>
            <Grid.Col span={2}>
              <Button
                color="red"
                fullWidth
                mt="xl"
                loading={isLoading}
                onClick={() => {
                  taskTempToEdit ?
                    handleDeleteTask(taskTempToEdit.id)
                    :
                    console.log('id da rimuovere non trovato')
                }}
              >
                Elimina
              </Button>
            </Grid.Col>

            <Grid.Col span={6}>
              <Button
                type="submit"
                fullWidth
                mt="xl"
                loading={isLoading}
              >
                Modifica
              </Button>
            </Grid.Col>
          </Grid>

        </form>
      </Modal>

      <Modal
        opened={modalSettingsOpened}
        onClose={() => {
          setModalSettingsOpened(false)
          formEdit.reset()
        }}
        title="Modifica task"
      >
        <form
          onSubmit={formSettings.onSubmit((values) => {
            handleSettings(values)
          })}>

          <ColorInput
            placeholder="Scegli un colore"
            label="Colore delle task"
            required
            {...formSettings.getInputProps('tasksColor')}
          />

          <Button
            type="submit"
            fullWidth
            mt="xl"
            loading={isLoading}
          >
            Salva
          </Button>

        </form>
      </Modal>
    </>
  )

}
