import { createSlice } from '@reduxjs/toolkit'
import { ViewMode } from "gantt-task-react"

const initialState = {
  appIsLoading: false,
  ganttViewDefault: ViewMode.Month,
  ganttShowSidebar: true,
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setGanttViewDefault(state, action) {
      state.ganttViewDefault = action.payload
    },
    setGanttShowSidebar(state, action) {
      state.ganttShowSidebar = action.payload
    }
  },
});

export const { setGanttViewDefault, setGanttShowSidebar } = generalSlice.actions;

export default generalSlice.reducer
