import { LoadingOverlay, MantineProvider } from '@mantine/core'
import {Navbar} from "./components/organisms/Navbar"
import {Dashboard} from "./pages/Dashboard"
import { Navigate, Route, Routes } from "react-router-dom"
import {UserSignin} from "./pages/UserSignin"
import {NotFound} from "./pages/NotFound"
import {FooterSimple} from "./components/organisms/Footer"
import {useAppDispatch, useAppSelector} from "./utils/hooks"
import {UserSignup} from "./pages/UserSignup"
import { useEffect, useState } from "react"
import {onAuthStateChanged} from "firebase/auth"
import { auth, db } from "./api/api-firebase"
import { logoutRequest, updateUserData } from "./store/reducers/user"
import {UserRecoverPassword} from "./pages/UserRecoverPassword"
import { NotificationsProvider } from '@mantine/notifications'
import { ScrollToTop } from "./utils/scrollToTop"
import { Helmet } from "react-helmet"
import { ListAll } from "./pages/ListAll"
import { collection, doc, onSnapshot, query, where, documentId } from "firebase/firestore"

const mainMenu = [
	{
		"link": "/gantt/all",
		"label": "Gantt del reparto"
	},
]

const unsignedMenu = [
	{
		"link": "/user/signin",
		"label": "Accedi"
	},
	{
		"link": "/user/signup",
		"label": "Registrati"
	},
]

export default function App() {

	const dispatch = useAppDispatch()

	const generalSettings = useAppSelector((state) => state.general)
	const user = useAppSelector((state) => state.user)

	useEffect(() => {

		// Listner sull'auth dell'utente
		onAuthStateChanged(auth, (currentUser) => {
			if (!currentUser) {
				dispatch(logoutRequest())
			}
		})

	}, [])

	useEffect(() => {
		if (user.data.docId !== "") {

			// Listener sui dati utente
			const q = query(collection(db, 'users'), where(documentId(), '==', user.data.docId));
			const unsubscribeUser = onSnapshot(q, snapshot => {

				// Aggiorno lo store di redux
				if (snapshot.docs[0]) {
					dispatch(updateUserData(snapshot.docs[0].data()))
				}

			})

			return () => {
				unsubscribeUser()
			}
		}
	}, [user.data.docId])

	return (
		<MantineProvider
			theme={{
				colorScheme: 'light',
				primaryColor: 'violet',
			}}
			withGlobalStyles
			withNormalizeCSS
		>
			<Helmet
				titleTemplate="%s | SD Gantt Tool"
				defaultTitle="SD Gantt Tool"
			>
				<meta name="description" content="" />
			</Helmet>

			<NotificationsProvider autoClose={5000}>

				<Navbar links={user.isLogged ? mainMenu : unsignedMenu}></Navbar>

				<ScrollToTop />

				<Routes>
					<Route path="/" element={user.isLogged ? <Dashboard /> : <Navigate to="user/signin" />} />
					<Route path="gantt/all" element={user.isLogged ? <ListAll /> : <Navigate to="user/signin" />} />
					<Route path="user/recover-password" element={<UserRecoverPassword />} />
					<Route path="user/signin" element={!user.isLogged ? <UserSignin /> : <Navigate to="/" />} />
					<Route path="user/signup" element={!user.isLogged ? <UserSignup /> : <Navigate to="/" />} />
					<Route path="*" element={< NotFound />} />
				</Routes>

				<FooterSimple links={user.isLogged ? mainMenu : unsignedMenu}></FooterSimple>

				<LoadingOverlay
					visible={generalSettings.appIsLoading}
					overlayBlur={5}
					style={{position: 'fixed'}}
				/>

			</NotificationsProvider>
		</MantineProvider>
	);
}
