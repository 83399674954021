import { useEffect, useState } from "react";
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import {
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Modal,
  Overlay,
  Select,
  Switch,
  TextInput,
  Title
} from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../utils/hooks"
import { IconCheck, IconCirclePlus, IconLayoutSidebar } from "@tabler/icons"
import { useForm } from "@mantine/form"
import {
  addDoc,
  collection,
  deleteDoc,
  doc, getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc
} from "firebase/firestore"
import { db } from "../api/api-firebase"
import { DateRangePicker } from "@mantine/dates"
import { addDays, currentDate, secondsToDate } from "../utils/utils"
import { showNotification } from "@mantine/notifications"
import { setGanttShowSidebar, setGanttViewDefault } from "../store/reducers/general"

export function ListAll() {

  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [usersTasks, setUsersTasks] = useState<any[]>([])
  const [tasks, setTasks] = useState<any[]>([])

  const ganttViewDefault = useAppSelector((state) => state.general.ganttViewDefault)
  const ganttShowSidebar = useAppSelector((state) => state.general.ganttShowSidebar)

  const handleExpanderClick = (task: Task) => {
    console.log('handleExpanderClick', task);
  }

  useEffect(() => {

    // Prendo i docs di tutti gli utenti
    const getUsersTasks = async() => {
      // Recupero utenti
      const q = query(collection(db, "users"));
      const users = await getDocs(q);

      // Creo un array temporaneo per gli utenti
      let usersTasks: any[] = [];

      // Uso la promise per renderlo sincrono
      await Promise.all(
        users.docs.map(async (user) => {
          const q = query(collection(db, "users", user.id, 'tasks'));
          const tasks = await getDocs(q);

          // Per ogni utente prendo le task
          let tasksTemp: any[] = [];
          tasks.docs.map((task) => {
            tasksTemp = [...tasksTemp, task.data()];
          })

          // Creo l'oggetto per il singolo utente
          const userTasks = {
            id: user.id,
            name: user.data().name,
            items: tasksTemp,
            tasksColor: user.data().tasksColor,
          }

          // Aggiungo all'array temporaneo
          usersTasks.push(userTasks)
        })
      )

      // Aggiorno lo stato
      setUsersTasks(usersTasks)

    }
    getUsersTasks();

  }, [])

  useEffect(() => {
    // Preparo l'array di tutti gli utenti
    let tasks: any[] = [];

    // Ciclo le tasks
    usersTasks.map((user) => {

      if (user.items.length > 0) {

        // Ordino gli items dell'utente per start e end per dare un range al "progetto utente"
        // Devo prima clonare l'array per non modificare quello originale
        const userTemp1 = [...user.items]
        const tasksOrderPerStart = userTemp1.sort((a: any, b: any) => {
          return a.start.seconds - b.start.seconds
        })

        const userTemp2 = [...user.items]
        const tasksOrderPerEnd = userTemp2.sort((a: any, b: any) => {
          return b.end.seconds - a.end.seconds
        })

        // Modeler per la task principale "progetto", corrispondente all'utente
        tasks = [...tasks, {
          id: user.id,
          start: secondsToDate(tasksOrderPerStart[0].start.seconds),
          end: secondsToDate(tasksOrderPerEnd[0].end.seconds),
          name: user.name,
          progress: 100,
          type: "project",
          hideChildren: false,
        }]

        // Ciclo le task dell'utente
        user.items.map((task: any) => {
          tasks = [...tasks, {
            id: task.name,
            start: secondsToDate(task.start.seconds),
            end: secondsToDate(task.end.seconds),
            name: task.name,
            progress: 100,
            type: "task",
            project: user.name,
            styles: {
              progressColor: user.tasksColor,
              progressSelectedColor: user.tasksColor,
            }
          }]
        })

      }

    })

    setTasks(tasks);

  }, [usersTasks])

  return (
    <>
      <Container mb="xl">

        <Group position="apart" align="center">

          <Title order={4}>Le task del reparto</Title>

        </Group>

        <Divider my="sm" variant="dotted"/>

        <Group position="apart" align="center">

          <Switch
            checked={ganttShowSidebar}
            label="Mostra sidebar"
            onChange={(event) => dispatch(setGanttShowSidebar(event.currentTarget.checked))}
          />

          <Select
            defaultValue={ganttViewDefault}
            placeholder="Visualizzazione per"
            data={[
              { value: ViewMode.Month, label: 'Vedi per mese' },
              { value: ViewMode.Week, label: 'Vedi per settimana' },
              { value: ViewMode.Day, label: 'Vedi per giorno' },
            ]}
            onChange={(value) => {
              dispatch(setGanttViewDefault(value as ViewMode))
            }}
          />

        </Group>

      </Container>

      <Container fluid>
        {
          tasks.length > 0 &&
            <Gantt
              tasks={tasks}
              locale="it"
              viewMode={ganttViewDefault}
              listCellWidth={ganttShowSidebar ? "150px" : ""}
              onExpanderClick={handleExpanderClick}
            />
        }
      </Container>
    </>
  )

}
