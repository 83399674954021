import {
  TextInput,
  PasswordInput,
  Checkbox,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Overlay,
} from '@mantine/core';
import {useState} from "react";
import { auth, db } from "../api/api-firebase";
import { collection, addDoc, getDocs, query, where, orderBy, serverTimestamp } from "firebase/firestore"
import {createUserWithEmailAndPassword} from "firebase/auth"
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconHeartHandshake } from "@tabler/icons"

export function UserSignup() {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const form = useForm({
    initialValues: {
      inviteCode: '',
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
      termsOfService: false,
    },

    validate: {
      name: (value) => (/\s/.test(value)) ? "L'username non può contenere spazi" : null,
      inviteCode: (value) => (value !== 'smartdev') ? 'Il codice di invito non è valido' : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email non valida'),
      password: (value) => (value.length < 6 ? "La password è troppo corta, usa almeno 6 caratteri" : null),
      confirmPassword: (value, values) =>
        value !== values.password ? 'Le password non corrispondono' : null,
    },
  });

  const signupUser = async (values: any) => {

    setIsLoading(true);

    // Controllo se l'username esiste già - Da rivedere perchè non essendo loggato non ho i permessi per leggere il db
    // const checkUsername = await searchFriends(values.name);
    const checkUsername = [];

    // L'username non esiste, procedo alla creazione
    if (checkUsername.length <= 0) {

      // Creo l'utente
      try {
        const res = await createUserWithEmailAndPassword(
          auth,
          values.email.trim(),
          values.password
        );
        if (res) {

          // Creo il doc dell'user

          const user = res.user;
          const q = query(collection(db, "users"), where("uid", "==", user.uid));
          const docs = await getDocs(q);

          // const avatar = `https://robohash.org/${Math.floor(Math.random() * (10000 - 1) + 1)}.png?set=set4`;
          // Rimuovo gli spazi dal values.name
          const avatar = `https://ui-avatars.com/api/?name=${values.name.replace(/\s/g, '')}+${values.surname.replace(/\s/g, '')}`;

          if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
              uid: user.uid,
              name: values.name,
              surname: values.surname,
              nameLowercase: values.name.toLowerCase(),
              email: user.email,
              signUpOn: serverTimestamp(),
              tasksColor: '#a3a3ff',
              avatar
            });
          }

          // Invio email di conferma
          // await sendEmailVerification(user)

          setIsLoading(false);

          showNotification({
            title: 'Benvenuto tra noi 🎉',
            message: "Il tuo account è stato creato con successo, non c'è bisogno di fare altro.",
            icon: <IconCheck />,
            color: 'green'
          })

          navigate("/user/signin");
        }
      } catch (err: any) {

        if (err.message.includes('auth/email-already-in-use')) {
          form.setFieldError('email', "L'email è già in uso")
        }

        setIsLoading(false);
      }

    } else {
      // L'username esiste già
      form.setFieldError('name', "L'username esiste già, scegline un altro")
      setIsLoading(false)
    }


  }

  return (
    <Container>

      <Title align="center" order={1}>Registrati</Title>

      <Container size={420}>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Sei già registrato?{' '}
          <Text component={Link} variant="link" to="/user/signin">Accedi</Text>
        </Text>

        <form onSubmit={form.onSubmit((values) => signupUser(values))}>
          <Paper withBorder shadow="md" p={30} mt={30} radius="md" sx={{position: 'relative'}}>

            {isLoading && <Overlay opacity={0.3} color="#000"/>}

            <TextInput mb="md" icon={<IconHeartHandshake size={20} />} maxLength={14} label="Codice di sicurezza" required {...form.getInputProps('inviteCode')} />
            <TextInput mb="md" maxLength={14} label="Nome" required {...form.getInputProps('name')} />
            <TextInput mb="md" maxLength={14} label="Cognome" required {...form.getInputProps('surname')} />
            <TextInput mb="md" label="Email" required {...form.getInputProps('email')} />
            <PasswordInput mb="md" label="Password" required {...form.getInputProps('password')} placeholder="min. 6 caratteri" />
            <PasswordInput mb="md" label="Conferma password" required {...form.getInputProps('confirmPassword')} />

            <Group position="apart" mt="md">
              <Checkbox
                required
                label="Accetto termini e condizioni della privacy policy"
                {...form.getInputProps('termsOfService', {type: 'checkbox'})}
              />
            </Group>

            <Button type="submit" fullWidth mt="xl" loading={isLoading && true}>Registrati</Button>

          </Paper>
        </form>

      </Container>
    </Container>
  );
}
