export const toFixedNoRounding = (num: number, length: number) => {
  return Number(num.toString().slice(0, (num.toString().indexOf(".")) + length))
}

export const formatNumber = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export const currentDate = new Date()

export const secondsToDate = (seconds: any) => {
  return new Date(seconds*1000)
}

export const addDays = (date: Date, days: number) => {
  const dateCopy = new Date(date)
  dateCopy.setDate(date.getDate() + days)
  return dateCopy;
}
