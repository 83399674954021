import {useState} from 'react';
import {
	createStyles,
	Header,
	Container,
	Group,
	Burger,
	Paper,
	Transition,
	Title,
	Menu,
	Avatar,
	Text,
	UnstyledButton, Divider
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
	IconLogout,
	IconChevronDown,
	IconMoon, IconSearch,
	IconNotes,
	IconBell
} from '@tabler/icons';
import {NavLink, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../utils/hooks";
import {auth} from "../../api/api-firebase";
import {signOut} from "firebase/auth";
import { Spacer } from "../atoms/Spacer"
import { appVersion } from "../../utils/constants"

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
	root: {
		position: 'fixed',
		zIndex: 100,
		top: 0,
		left: 0,
		right: 0,

		[theme.fn.smallerThan('sm')]: {
			marginBottom: 30,
		},
	},

	dropdown: {
		position: 'absolute',
		top: HEADER_HEIGHT,
		left: 0,
		right: 0,
		zIndex: 101,
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
		borderTopWidth: 0,
		overflow: 'hidden',

		[theme.fn.largerThan('sm')]: {
			display: 'none',
		},
	},

	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
	},

	logo: {
		textDecoration: 'none',
		color: theme.colors.primaryColor,
		'&, &:hover': {
			textDecoration: 'none',
		},
	},

	links: {
		[theme.fn.smallerThan('sm')]: {
			display: 'none',
		},
	},

	burger: {
		[theme.fn.largerThan('sm')]: {
			display: 'none',
		},
		[theme.fn.smallerThan('sm')]: {
			marginLeft: 10,
		},
	},

	link: {
		display: 'block',
		lineHeight: 1,
		padding: '8px 12px',
		borderRadius: theme.radius.sm,
		textDecoration: 'none',
		color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
		fontSize: theme.fontSizes.sm,
		fontWeight: 500,
		cursor: 'pointer',

		[theme.fn.smallerThan('sm')]: {
			borderRadius: 0,
			padding: theme.spacing.md,
			marginLeft: 10,
		},

		[theme.fn.largerThan('sm')]: {
			'&:hover': {
				backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
			},
		},
	},

	linkWatchlist: {
		display: "none",
		[theme.fn.smallerThan('sm')]: {
			display: "block",
			paddingLeft: 0,
			paddingRight: 0,
		},
	},

	linkSearch: {
		[theme.fn.smallerThan('sm')]: {
			paddingLeft: 0,
			paddingRight: 0 + ' !important',
		},
	},

	linkNotification: {
		position: 'relative',
		marginLeft: 10,
		[theme.fn.smallerThan('sm')]: {
			paddingLeft: 0,
			paddingRight: 0 + ' !important',
		},
	},

	notificationBubble: {
		position: 'absolute',
		borderRadius: '50%',
		backgroundColor: theme.colors.red,
		width: 7,
		height: 7
	},

	linkActive: {
		'&, &:hover': {
			backgroundColor: theme.fn.variant({variant: 'light', color: theme.primaryColor}).background,
			color: theme.fn.variant({variant: 'light', color: theme.primaryColor}).color,
		},
	},

	user: {
		color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
		padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
		borderRadius: theme.radius.sm,
		transition: 'background-color 100ms ease',

		'&:hover': {
			backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
		},

		[theme.fn.smallerThan('xs')]: {
			display: 'none',
		},
	},

	userActive: {
		backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
	},

	tabs: {
		[theme.fn.smallerThan('sm')]: {
			display: 'none',
		},
	},

	tabsList: {
		borderBottom: '0 !important',
	},


}));

interface HeaderProps {
	links: { link: string; label: string }[];
}

export function Navbar({links}: HeaderProps) {

	const userState = useAppSelector((state) => state.user)
	const generalSettings = useAppSelector((state) => state.general)

	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const {classes, cx} = useStyles();
	const [opened, {toggle}] = useDisclosure(false);
	const [userMenuOpened, setUserMenuOpened] = useState(false);

	const largeScreen = useMediaQuery('(min-width: 768px)');

	const items = links.map((link) => (
		<NavLink
			key={link.label}
			to={link.link}
			className={({isActive}) => classes.link + ' ' + (isActive ? classes.linkActive : '')}
		>
			{link.label}
		</NavLink>
	));

	return (
		<>
			<Header height={HEADER_HEIGHT} className={classes.root}>
				<Container className={classes.header}>

					<Title order={4}>
						<Group>
							<Text
								component={NavLink}
								variant="gradient"
								gradient={{ from: 'lime', to: 'green', deg: 45 }}
								to="/"
								className={classes.logo}
							>
								Gantt
							</Text>
						</Group>
					</Title>

					<Group spacing={0}>

						<Group spacing={5} className={classes.links}>
							{items}
							{
								userState.isLogged &&
									<Menu
										width={260}
										position="bottom-end"
										transition="pop-top-right"
										onClose={() => setUserMenuOpened(false)}
										onOpen={() => setUserMenuOpened(true)}
									>
										<Menu.Target>
											<UnstyledButton
												className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
											>
												<Group spacing={7}>
													<Avatar color="white" src={userState.data.avatar} alt={userState.data.name} radius="xl" size={20} />
													<Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
														{userState.data.name}
													</Text>
													<IconChevronDown size={12} stroke={1.5} />
												</Group>
											</UnstyledButton>
										</Menu.Target>
										<Menu.Dropdown>
											{/*<Menu.Item icon={<IconSettings size={14} stroke={1.5} />}>Impostazioni</Menu.Item>*/}
											<Menu.Item
												icon={<IconLogout size={14} stroke={1.5} />}
												onClick={async () => {
													await signOut(auth)
													navigate('/user/signin')
												}}>Logout
											</Menu.Item>
										</Menu.Dropdown>
									</Menu>
							}
						</Group>

						<Burger opened={opened} onClick={toggle} className={classes.burger} size="sm"/>
						<Transition transition="pop-top-right" duration={200} mounted={opened}>
							{(styles) => (
								<Paper onClick={toggle} className={classes.dropdown} withBorder style={styles}>
									{items}
									<Text
										component="a"
										onClick={async () => {
											await signOut(auth)
											navigate('/user/signin')
										}}
										className={classes.link}
									>
										Logout
									</Text>
								</Paper>
							)}
						</Transition>

					</Group>

				</Container>
			</Header>
			<Spacer smallerXs={120} largerXs={80} />
		</>
	);
}
